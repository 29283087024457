<template>
  <div class="container mescroll-touch">
    <img class="poster" src="@/assets/images/ElectronicPhoto.jpg" />
    <img class="uploadImg" v-show="cropperImg" :src="cropperImg" alt="" srcset="">
    <van-uploader class="uploadBtn" accept="image/*" :after-read="afterRead">
      <div class="uploadPicture"></div>
    </van-uploader>
    <div class="generateImage" @click="generateImage"></div>
    <van-popup class="vanPopup" v-model="showCropper" :close-on-click-overlay="false" @opened="popupOpened">
      <p class="title">图片裁剪</p>
      <div class="cropper">
        <img class="cropperImage" ref="cropperImage" :src="fileSrc" alt="">
      </div>
      <div class="cropperBtn" @click="sureCropper">裁剪</div>
    </van-popup>
    <van-popup class="vanPopup" v-model="showGenerateImage" :close-on-click-overlay="false" @opened="generateImageOpened">
      <van-icon class="close" name="close" @click="showGenerateImage = false" />
      <span class="tips">请长按保存到手机后分享至朋友圈</span>
      <div class="generateImageBox" ref="generateImageBox" v-if="!electronicPhoto">
        <img class="generateImagePoster" src="@/assets/images/ElectronicPhoto1.jpg" />
        <img class="uploadImg1" v-show="cropperImg" :src="cropperImg" alt="" srcset="">
      </div>
      <img class="electronicPhoto" v-else :src="electronicPhoto" alt="">
    </van-popup>

    <van-overlay :show="ElectronicPhotoAgree" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="InformedConsent">
          <p class="title">使用准则</p>
          <p>总则：<br/>本平台旨在为平台用户创建高效的沟通渠道，连接用户，支持业务，孵化创新。</p><br/>
          <p>内容发布规则：</p>
          <ul class="ul">
            <li>您可使用本平台发表属于您原创或您有权发表的观点看法、文字、信息、图片、视频等内容。您在本平台发布的任何内容、信息均不代表公司的观点、立场或政策，除法律法规明确规定外，您需独立承担所发布内容的责任<span>（所上传的视频及照片中请勿包含除本人以外的其他个人（包括但不限于患者、医生、医护、非临人员和政府官员）的个人信息（姓名、联系方式、工作单位、职业、面部影像等）</span></li>
            <li>您通过平台发布内容时必须遵守有关法律法规及强生公司相关政策，不得利用平台制作、复制、发布、传播、存储任何违反法律法规、危害国家安全、可能对用户财产安全或信息安全造成损失的信息、营销信息、低俗不当信息、不良信息以及不实信息等内容。</li>
            <li>用户在本平台内的所有言论均不得违反国家的法律法规和强生公司相关政策。根据《互联网新闻信息服务管理规定》和强生公司相关政策，用户的言论不得含有下列内容：
              <p>（一）违反中华人民共和国宪法确定的基本原则</p>
              <p>（二）危害国家安全，泄露国家秘密，颠覆国家政权、破坏国家统一</p>
              <p>（三）损害国家荣誉和利益</p>
              <p>（四）煽动民族仇恨，民族歧视，破坏民族团结</p>
              <p>（五）破坏国家宗教政策，宣扬邪教和封建迷信</p>
              <p>（六）散布谣言，扰乱社会秩序，破坏社会稳定</p>
              <p>（七）散布淫秽，色情，赌博，暴力，恐怖或者教唆犯罪</p>
              <p>（八）侮辱或者诽谤他人，侵害他人合法权益</p>
              <p>（九）煽动非法集会，结社，游行，示威，聚众扰乱社会秩序</p>
              <p>（十）以非法民间组织名义活动</p>
              <p>（十一）含有法律、法规、规章、地方规范性文件、国家政策、政府通知、公序良俗等禁止的内容</p>
              <p>（十二）违反公司合规政策</p>
              <p>（十三）涉及公司商业机密的内容</p>
              <p><span class="highlight">（十四）所上传的视频及照片中请勿包含除本人以外的其他个人（包括但不限于患者、医生、医护、非临人员和政府官员）的个人信息（姓名、联系方式、工作单位、职业、面部影像等）</span></p>
              <p>（十五）本平台认为不利于平台生态，可能给平台造成损失的内容（对于有界定争议的内容，平台运营管理委员会享有最终决断权）</p>
            </li>
          </ul><br/>
          <p>平台运营管理委员会：</p>
          <ul class="ul">
            <li>用户在平台发表违规言论发布违规内容的，平台运营管理委员会成员（缝线HCC champion，平台运营管理人员）有权删除其全部或部分内容，公司有权依据公司政策视情节严重程度给予处罚</li>
          </ul><br/>
          <p>内容分享与使用：</p>
          <ul class="ul">
            <li>本平台中所发布的信息内容仅限在公司内部有访问权限的人员之间进行分享。未经平台允许不可复制、读取、采用、统计平台的信息内容及相关数据，或进行任何形式的销售和商业使用，或者向第三方泄露、提供或允许第三方为任何形式的使用。</li>
          </ul><br/>
          <div class="agree" @click="agree">同意</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import storage from "../../common/utils/storage";
import { mapState } from 'vuex'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import html2canvas from 'html2canvas';

export default {
  name: 'ElectronicPhoto',
  data() {
    return {
      fileSrc: '',
      showCropper: false,
      cropper: null,
      cropperImg: '',
      showGenerateImage: false,
      electronicPhoto: '',
      ElectronicPhotoAgree: false
    }
  },
  computed: {
    ...mapState(['Openid'])
  },
  created() {
    this.getUser();
    let ElectronicPhotoAgree = storage.getStore('ElectronicPhotoAgree');
    if(!ElectronicPhotoAgree) {
      this.ElectronicPhotoAgree = true;
    }
  },
  methods: {
    getUser() {
      this.$axios.post('/h5api/api/Web/GetJNJUserByWeb/UserState?OpenID=' + this.Openid).then(res => {
        sessionStorage.setItem("JNJUserSource", 'h5api');
        if(res.RetMsg == '员工未授权！') {
          storage.setStore('scope', 'snsapi_userinfo');
          this.$router.push({
            path: '/authorize',
            query: {scope: 'snsapi_userinfo'}
          })
        } else if(res.RetMsg == '员工未登录！') {
          this.$router.push('/JNJUser');
        } else if(res.RetCode == '-1') {
          alert(res.RetMsg)
        }
      })
    },
    afterRead(file) {
      this.fileSrc = file.content;
      this.showCropper = true;
    },
    popupOpened() {
      if(!this.cropper) {
        this.initCropper();
      }
      this.cropper.replace(this.fileSrc);
    },
    initCropper() {
      this.cropper = new Cropper(this.$refs.cropperImage, {
        aspectRatio: 16 / 16,
        // aspectRatio: this.autoCropWidth / this.autoCropHeight,
        viewMode: 1,
        dragMode: 'move',
        preview: '.before',
        background: false,
        autoCropArea: 1,
        zoomOnWheel: true,
      })
    },
    sureCropper() {
      this.cropperImg = this.cropper
        .getCroppedCanvas({
          imageSmoothingQuality: 'high'
        })
        .toDataURL('image/jpeg');
      this.showCropper = false;
    },
    generateImage() {
      if(this.cropperImg) {
        this.electronicPhoto = '';
        this.showGenerateImage = true;
      } else {
        this.$toast.fail('请上传图片');
      }
    },
    generateImageOpened() {
      html2canvas(this.$refs.generateImageBox).then((canvas) => {
        this.electronicPhoto = canvas.toDataURL("image/png");
        let base64Img = this.electronicPhoto.substring(this.electronicPhoto.indexOf(',')+1);
        if(this.electronicPhoto) {
          this.$axios.post('/h5api/api/Web/Files/FileUpload', {fl: base64Img}).then(res => {})
        }
      });
    },
    agree() {
      storage.setStore('ElectronicPhotoAgree', 'true');
      this.ElectronicPhotoAgree = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .poster {
    display: block;
    width: 100%;
  }
  .uploadImg {
    position: absolute;
    top: 21%;
    left: 15.5%;
    width: 69%;
    height: 267px;
    z-index: 99;
  }
  .uploadBtn {
    position: absolute;
    top: 70.5%;
    left: 10%;
    width: 37%;
    height: 45px;
    z-index: 99;
  }
  .uploadBtn /deep/ .van-uploader__wrapper {
    width: 100%;
    height: 100%;
  }
  .uploadBtn /deep/ .van-uploader__input-wrapper {
    width: 100%;
    height: 100%;
  }
  .uploadPicture {
    width: 100%;
    height: 100%;
  }
  .generateImage {
    position: absolute;
    top: 70.5%;
    right: 10%;
    width: 37%;
    height: 45px;
    z-index: 99;
  }
  .vanPopup {
    width: 80%;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 10px;
    box-sizing: border-box;
    .title {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .cropper {
      height: 200px;
      .cropperImage {
        display: block;
        max-width: 100%;
      }
    }
    .cropperBtn {
      width: 70px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background-color: #66B1FF;
      color: #FFFFFF;
      font-size: 14px;
      border-radius: 3px;
      margin: 15px auto 5px;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 4px;
      font-size: 18px;
    }
    .tips {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      color: #333333;
      text-align: center;
    }
    .generateImageBox {
      position: relative;
      margin-top: 6px;
      .generateImagePoster {
        display: block;
        width: 100%;
      }
      .uploadImg1 {
        position: absolute;
        // top: 18%;
        top: 17.5%;
        left: 11%;
        width: 78%;
        height: 225px;
        z-index: 99;
      }
    }
    .electronicPhoto {
      display: block;
      width: 100%;
      margin-top: 6px;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.InformedConsent {
  width: 90%;
  height: 80%;
  background-color: #fff;
  border-radius: 6px;
  font-size: 12px;
  padding: 12px;
  box-sizing: border-box;
  overflow: auto;
  .title {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  p {
    line-height: 1.8;
  }
  .ul {
    list-style-type: disc;
    margin-left: 30px;
    li {
      line-height: 1.8;
    }
  }
  .child_ul {
    list-style-type: circle;
    margin-left: 14px;
    li {
      line-height: 1.8;
    }
  }
  .highlight {
    font-weight: bold;
    background-color: yellow;
  }
  .agree {
    width: 80px;
    line-height: 34px;
    border-radius: 4px;
    background-color: #CA001B;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    margin: 0 auto 10px;
  }
}
.van-overlay {
  z-index: 999;
}
</style>